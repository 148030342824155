import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TwoColumnsComponent } from './two-columns/two-columns.component';
import { ColumnRightComponent } from './column-right/column-right.component';
import { ColumnLeftComponent } from './column-left/column-left.component';

@NgModule({
  declarations: [TwoColumnsComponent, ColumnLeftComponent, ColumnRightComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [TwoColumnsComponent, ColumnLeftComponent, ColumnRightComponent],
})
export class ColumnsModule {}
