import { Component, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ess-scroll-gallery-wrapper',
  templateUrl: './scroll-gallery-wrapper.component.html',
  styleUrls: ['./scroll-gallery-wrapper.component.scss'],
})
export class ScrollGalleryWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild('galleryContainer', { static: false }) galleryContainer: ElementRef<HTMLElement>;
  @ViewChild('galleryInnerContainer', { static: false }) galleryInnerContainer: ElementRef<HTMLElement>;
  galleryContainerEl: HTMLElement;
  galleryInnerContainerEl: HTMLElement;
  scrollLeftButtonDisabled = false;
  scrollRightButtonDisabled = true;
  lastScrollPosition = 0;
  disableButtons: boolean;
  resizeObserver: ResizeObserver;

  private destroy$ = new Subject<boolean>();

  ngAfterViewInit(): void {
    this.galleryContainerEl = this.galleryContainer.nativeElement;
    this.galleryInnerContainerEl = this.galleryInnerContainer.nativeElement;

    fromEvent(this.galleryContainerEl, 'scroll')
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(() => this.recalculate());

    if ('ResizeObserver' in window) {
      this.resizeObserver = new ResizeObserver(() => this.onGalleryResize());
      this.resizeObserver.observe(this.galleryInnerContainerEl);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.resizeObserver?.disconnect();
  }

  recalculate(): void {
    const currentScrollPosition = this.galleryContainerEl.scrollLeft;
    const scrollingRight = currentScrollPosition < this.lastScrollPosition;
    const scrollingLeft = currentScrollPosition > this.lastScrollPosition;
    // box.scrollWidth === box.clientWidth + box.scrollLeft
    const maxScrollLeft = this.galleryContainerEl.scrollWidth - this.galleryContainerEl.clientWidth;

    this.lastScrollPosition = currentScrollPosition;

    if (maxScrollLeft === 0) {
      this.scrollLeftButtonDisabled = true;
      this.scrollRightButtonDisabled = true;
      return;
    }

    if (scrollingLeft) {
      // Avoid enable button when scrollbar bounces (IOS)
      if (this.galleryContainerEl.scrollLeft > 10) {
        this.scrollRightButtonDisabled = false;
      }

      if (this.galleryContainerEl.scrollLeft >= maxScrollLeft) {
        this.scrollLeftButtonDisabled = true;
      }
    } else if (scrollingRight) {
      // Avoid enable button when scrollbar bounces (IOS)
      if (maxScrollLeft - this.galleryContainerEl.scrollLeft > 10) {
        this.scrollLeftButtonDisabled = false;
      }

      if (this.galleryContainerEl.scrollLeft <= 0) {
        this.scrollRightButtonDisabled = true;
      }
    }
  }

  scrollIt(direction: 'right' | 'left'): void {
    const galleryContainer = this.galleryContainerEl;

    if (direction === 'left') {
      this.galleryContainerEl.scrollTo({
        left: galleryContainer.scrollLeft + galleryContainer.clientWidth,
        behavior: 'smooth',
      });
    } else if (direction === 'right') {
      this.galleryContainerEl.scrollTo({
        left: galleryContainer.scrollLeft - galleryContainer.clientWidth,
        behavior: 'smooth',
      });
    }
  }

  private onGalleryResize() {
    const isScrolledToLeftEnd =
      this.galleryContainerEl.scrollLeft + this.galleryContainerEl.clientWidth >=
      this.galleryInnerContainerEl.scrollWidth;

    if (this.scrollLeftButtonDisabled && !isScrolledToLeftEnd) {
      this.scrollIt('left');
      return;
    }

    this.recalculate();
  }
}
