import { Component, Input } from '@angular/core';
import { Review } from '../../models/review.model';

@Component({
  selector: 'ess-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss'],
})
export class ReviewListComponent {
  @Input() reviews: Review[] = [];
  @Input() totalRating: number | null | undefined;
}
