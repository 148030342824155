import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule as DropdownPrimeNG } from 'primeng/dropdown';
import { DropdownComponent } from './components/dropdown.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [CommonModule, FormsModule, DropdownPrimeNG],
  exports: [DropdownComponent],
})
export class DropdownModule {}
