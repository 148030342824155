<div
  class="select"
  *ngIf="collection?.length && (title || filterCategories?.length)"
  fxLayout="row wrap"
  fxLayoutAlign="space-between center"
  @fadeInAnimation
>
  <div>
    <h4 *ngIf="title">{{ title }}</h4>
  </div>

  <div class="filters_container" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap=" 1.25rem">
    <ng-container *ngFor="let filter of filterCategories">
      <mat-form-field *ngIf="filterOptions[filter.key]?.length > 1">
        <mat-label>Filter by {{ filter.title }}</mat-label>

        <mat-select
          (selectionChange)="filterCollection(collection, filter.key, $event.value)"
          [value]="filterValues[filter.key]"
        >
          <mat-option
            *ngFor="let option of filterOptions[filter.key]; let index = index"
            [value]="filter.key === 'start_date' ? index : option"
          >
            {{ option }}
          </mat-option>

          <mat-option [value]="false">See All</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</div>
<ess-scroll-gallery-wrapper>
  <div
    data-cy="scroll-gallery-item"
    *ngFor="let item of filteredCollection"
    class="item"
    [ngClass]="{pointer: type === 'area' || type === 'neighborhood'}"
    fxFlex="1 1 9.375rem"
    (click)="emitItemClicked($event, item)"
    @fadeInAnimation
  >
    <!-- default -->
    <a [href]="buildLink(link, item)" target="_blank" *ngIf="link && type !== 'days'; else neighborhood">
      <div class="item_image">
        <figure>
          <img [src]="getImageUrl(item.featured_image || item.image, 150, 150)" />
        </figure>
      </div>

      <h2>{{ item.title }}</h2>
      <div *ngIf="item.description" class="item_description" [title]="item.description">
        <span [innerHTML]="item.description | slice: 0:60"></span><span>...</span>
      </div>

      <h5 class="category" *ngIf="item.category || item.subcategory">
        {{ item.category }}
        <span *ngIf="item.category !== item.subcategory && !item.start_date && !item.end_date">
          {{ item.subcategory }}
        </span>

        <span *ngIf="item.start_date || item.end_date">
          {{ item.start_date | date: 'EEEE, MMM d, y' }}<span *ngIf="item.end_date"> - </span
          >{{ item.end_date | date: 'EEEE, MMM d, y' }}
        </span>

        <span *ngIf="item.neighborhood">
          {{ item.neighborhood }}
        </span>
      </h5>

      <h5 *ngIf="item.user || item.arrival_date">
        {{ item.user }}
        <span *ngIf="item.arrival_date">
          {{ item.arrival_date | date: 'EEEE, MMM d, y' }}
        </span>
      </h5>

      <h5 *ngIf="item.city" class="grayed">
        {{ item.city }}
      </h5>
      <h5 class="related" *ngIf="item.exclusive_hotel">
        Exclusive for guests of:
        <span>{{ item.exclusive_hotel.title }}</span>
      </h5>
      <div *ngIf="item.is_amex" class="amex-tag">Amex</div>
      <ess-experience-ranking [internalRanking]="item.internal_ranking"></ess-experience-ranking>
    </a>

    <!-- neighborhood -->
    <ng-template #neighborhood>
      <div class="item_image" fxLayoutAlign="end start">
        <figure>
          <img [src]="getImageUrl(item.featured_image || item.image, 150, 150)" />
        </figure>
      </div>
      <h2>{{ item.title }}</h2>
      <div *ngIf="item.description" class="item_description" [title]="item.description">
        <span [innerHTML]="item.description | slice: 0:60"></span><span>...</span>
      </div>
    </ng-template>
  </div>
</ess-scroll-gallery-wrapper>
