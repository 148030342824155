import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ScrollGalleryWrapperComponent } from './scroll-gallery-wrapper.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatButtonModule],
  declarations: [ScrollGalleryWrapperComponent],
  exports: [ScrollGalleryWrapperComponent],
})
export class ScrollGalleryWrapperModule {}
