import { ReservationChannelType } from './reservation-channel.model';
import { ExperienceType, ICustomLocation } from '@ess-front/shared';

export interface DestinationsList {
  count: number;
  next: string;
  previous: string;
  results: DestinationResult[];
}

export interface DestinationResult {
  id: number;
  group: string;
  title: string;
  image: string;
  region: string;
  slug: string;
  description: string;
  content_type: string;
  uber: boolean;
  lyft: boolean;
  why_go: string;
  when_to_go: string[];
  when_to_visit: string;
  getting_there: string;
  neighborhoods: string[];
  status: string;
}

export interface Destination {
  [key: string]: any;
  airports: Airport[];
  all_airports: string;
  bookings: string;
  currency: string[];
  description: string;
  editor: Editor;
  events: string;
  experiences: string;
  gallery: Image[];
  getting_there: string[];
  hotels: string;
  id: number;
  keep_in_mind: string;
  lat?: string;
  length_of_stay: string[];
  lng?: string;
  location: ICustomLocation;
  neighborhoods: DestinationResourceBE[];
  places: string;
  quote: string;
  readings: Reading[];
  restaurants: string;
  slug: string;
  status: string;
  tag_great_for: string[];
  timezone: string[];
  title: string;
  visa_situation: string[];
  when_to_go: string[];
  when_to_visit: string[];
  why_go: string[];
}

export interface DestinationResourceBE {
  added?: boolean;
  address?: string;
  category?: string;
  city?: string;
  dayId?: number;
  description?: string;
  end_date?: string;
  exclusive_hotel?: ExclusiveHotel | null;
  experience_modules?: DayExperienceModule[];
  featured_image?: string;
  id?: number;
  image?: string;
  internal_ranking?: InternalRanking;
  is_amex?: boolean;
  is_restaurant?: boolean;
  lat?: string;
  lng?: string;
  location?: ICustomLocation;
  matching?: MatchingBE;
  neighborhood?: Neighborhood;
  object_id?: number;
  object_type?: string;
  reservation_channel_type?: ReservationChannelType | null;
  slug?: string;
  start_date?: string;
  style?: string;
  subcategory?: string;
  tag_best_for?: string[];
  tag_great_for?: string[];
  tag_style?: string;
  tag_way?: string;
  title: string;
  type?: string;
  type_slug?: ExperienceType;
  website?: string;
  zipcode?: string;
  feedback_average_score: number | null;
}

interface DayExperienceModule {
  exact_time: boolean;
  exclusive_hotel: ExclusiveHotel | null;
  from_time: string;
  from_time_string: string;
  hotel_id: number | null;
  id: number;
  title: string;
}

interface Image {
  local_src: string;
  title: string;
  description: string;
}

export interface Airport {
  name?: string;
  title?: string;
  iata: string;
  icao: string;
  city: string;
  airport_type: string;
}

export interface DestinationBooking {
  featured_image: string;
  hash: string;
  start_date: string;
  title: string;
  user: string;
}

export interface Editor {
  first_name: string;
  last_name: string;
  bio: string;
  email: string;
  phone: string;
  avatar: string;
}

export interface Reading {
  id: number;
  title: string;
  author: string;
  year: number;
  comment: string;
  reading_type: number;
  reading_type_display: string;
  document: string;
  website: string;
  image: string;
}

export interface MatchingBE {
  breakdown: Record<string, string>;
  created: string;
  percentage: string;
  points_obtained: number;
  possible_points: number;
}

export interface ExclusiveHotel {
  id: number;
  title: string;
}

interface Neighborhood {
  id: number;
  title: string;
  slug: string;
  description: string;
  image: string;
}

export enum InternalRanking {
  CURATED = 40,
  NON_CURATED = 30,
  PERMANENTLY_CLOSED = 10,
  SIGNATURE = 50,
  TEMPORARY = 20,
}
