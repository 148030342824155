export interface IListItem {
  label: string;
  value: string;
}

export class ListItem {
  label = null;
  value = null;

  constructor(label: string, value: string | number) {
    this.label = label ? label.toString() : null;
    this.value = value ? value.toString() : null;
  }
}
