import { NotificationComponent } from './notification.component';
import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

export interface ISnackBarData {
  message: string;
  action?: string;
  html?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  defaultConfig: MatSnackBarConfig = {
    // You can use .success/.error for success/error(styles.css)
    // or pass other classes to the notification methods
    panelClass: 'success',
    horizontalPosition: 'right',
    verticalPosition: 'top',
    duration: 8000,
  };

  constructor(public snackBar: MatSnackBar) {}

  notify(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<any> {
    return this.snackBar.open(message, action, { ...this.defaultConfig, ...config });
  }

  notifyWithComponent(
    message?: string,
    config?: MatSnackBarConfig,
    action?: string,
    html?: string,
    component = NotificationComponent,
  ): MatSnackBarRef<any> {
    return this.snackBar.openFromComponent(component, {
      ...this.defaultConfig,
      ...config,
      data: { message, action, html },
    });
  }
}
