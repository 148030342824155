import { Inject, Injectable } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { map, take, distinctUntilChanged } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { IImageSizes } from '../models/image-size.model';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(
    private mediaObserver: MediaObserver,
    @Inject('env') private environment: any,
  ) {}

  /* sizes param works with the https://github.com/angular/flex-layout/wiki/Responsive-API breakpoints:
        mqAlias | mediaQuery
    xs === 'screen and (max-width: 599px)'
    sm === 'screen and (min-width: 600px) and (max-width: 959px)'
    md === 'screen and (min-width: 960px) and (max-width: 1279px)'
    lg === 'screen and (min-width: 1280px) and (max-width: 1919px)'
    xl === 'screen and (min-width: 1920px) and (max-width: 5000px)'

    lt-sm === 'screen and (max-width: 599px)'
    lt-md === 'screen and (max-width: 959px)'
    lt-lg === 'screen and (max-width: 1279px)'
    lt-xl === 'screen and (max-width: 1919px)'

    gt-xs === 'screen and (min-width: 600px)'
    gt-sm === 'screen and (min-width: 960px)'
    gt-md === 'screen and (min-width: 1280px)'
    gt-lg === 'screen and (min-width: 1920px)'
  */
  getImageObs$(url: string, height: number, width: number, sizes: IImageSizes): Observable<string> {
    if (url && sizes && url.includes(this.environment.cloudinaryURLBase)) {
      const u = url.split('upload/')[1];

      // NOTE: Can't be used for [style.backgroundImage] binding (too much detection change cycles)
      return this.mediaObserver.asObservable().pipe(
        distinctUntilChanged((prev, curr) => prev[0].mqAlias === curr[0].mqAlias),
        take(1),
        map(mediaChanges => {
          const media = mediaChanges.pop();
          const h = (sizes[media.mqAlias] && sizes[media.mqAlias].height) || height;
          const w = (sizes[media.mqAlias] && sizes[media.mqAlias].width) || width;
          return `${this.environment.cloudinaryURL}c_fill,fl_progressive,q_auto,f_auto,dpr_auto,h_${h},w_${w}/${u}`;
        }),
      );
    }
    return of(null);
  }

  getImageUrl(url: string, height: number, width: number): string {
    let u = url;
    if (url && url.includes(this.environment.cloudinaryURLBase)) {
      u = url.split('upload/')[1];
      const h = height ? `h_${height}` : '';
      const w = width ? `w_${width}` : '';
      const q = (!height && !width) || height > 230 || width > 230 ? 'auto' : '100';
      return `${this.environment.cloudinaryURL}c_fill,fl_progressive,q_${q},f_auto,dpr_auto,${h},${w}/${u}`;
    }
    // Not a cloudinary? S3, using image url as is.
    return u;
  }
}
