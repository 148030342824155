<div class="header">
  <p class="font-semibold">Global rating:</p>
  <ess-star-group [rate]="1"></ess-star-group>
  <p class="rating font-semibold">{{ totalRating }}</p>
  <p class="font-semibold">-</p>
  <p class="font-regular">{{ reviews.length }} reviews</p>
</div>
<section class="ratings">
  <ess-review [review]="review" *ngFor="let review of reviews"></ess-review>
</section>
