import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ess-star-group',
  templateUrl: './star-group.component.html',
  styleUrls: ['./star-group.component.scss'],
})
export class StarGroupComponent implements OnInit {
  @Input() rate: number | undefined;
  rateArr: number[] = [];

  ngOnInit(): void {
    if (this.rate) {
      this.rateArr = Array(10)
        .fill(1, 0, this.rate)
        .filter(value => !!value);
    }
  }
}
