<mat-dialog-content>
  <div class="template__close_button" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
  <div *ngIf="detailsURL" class="template__details_button">
    <a href="{{ detailsURL }}" target="_blank" rel="noopener noreferrer" title="Go to details"
      ><mat-icon>north_east</mat-icon></a
    >
  </div>

  <div class="template__container" fxLayout="row wrap" fxLayoutAlign="start" [ngClass]="{is_place: isPlace}">
    <div class="template__image" [style.backgroundImage]="backgroundImage" fxFlex="1 0 15.625rem" @fadeInAnimation>
    </div>

    <div
      fxFlex="1 0 100%"
      fxFlex.lt-sm="0 1 auto"
      fxLayout="row wrap"
      fxLayoutAlign="start start"
      class="template__content"
      @fadeInAnimation
    >
      <div class="template__details" fxFlex="1 1 100%" fxLayout="row wrap">
        <h4 *ngIf="data?.details?.from_time_display" fxFlex="1 1 100%">
          <strong>
            {{ data?.details?.from_time_string }}
            <span *ngIf="data?.details?.from_time_string && data?.details?.from_time_display">.</span>
            {{ getFormattedTime(data?.details?.from_time_display) }}
          </strong>
        </h4>

        <h2 fxFlex="1 1 100%">
          {{ data?.details?.title }}
        </h2>

        <h6>
          {{ data?.details?.category }}
          <span *ngIf="data?.details?.category !== data?.details?.subcategory">
            {{ data?.details?.subcategory }}
          </span>
        </h6>

        <div *ngIf="data?.details?.status" fxFlex="1 1 100%">
          <h5>STATUS</h5>
          <p>{{ data?.details?.status }}</p>
        </div>

        <div *ngIf="data?.details?.provider_booking_reference" fxFlex="1 1 100%">
          <h5>BOOKING REFERENCE</h5>

          <p>{{ data?.details?.provider_booking_reference }}</p>
        </div>

        <div *ngIf="data?.details?.reservation_details && data?.details?.status_slug === 'confirmed'" fxFlex="1 1 100%">
          <h5 class="title">CONFIRMATION DETAILS</h5>

          <div [innerHTML]="data?.details?.reservation_details"></div>
        </div>

        <div class="template__editorial_content" fxFlex="1 1 100%" *ngIf="data?.details?.editorial_content?.length">
          <div *ngFor="let content of data?.details?.editorial_content">
            <h5>{{ content.title | uppercase }}</h5>

            <div [innerHTML]="content.content"></div>
          </div>
        </div>

        <div class="template__place_description" fxFlex="1 1 100%" *ngIf="data?.details?.description && isPlace">
          <h5>DESCRIPTION</h5>

          <div [innerHTML]="data?.details?.description"></div>
        </div>

        <ng-container *ngIf="reviews$ | async as reviews">
          <ess-review-list
            *ngIf="reviews.length > 0"
            [reviews]="reviews"
            [totalRating]="averageFeedbackScore"
          ></ess-review-list>
        </ng-container>

        <div *ngIf="data?.details?.type_slug === 'accommodation'" fxFlex="1 1 100%">
          <div *ngIf="data?.details?.hotel_check_out_date">
            <h5>CHECK OUT DATE</h5>

            <p>{{ data?.details?.hotel_check_out_date }}</p>
          </div>

          <div *ngIf="data?.details?.number_of_nights">
            <h5>NUMBER OF NIGHTS</h5>

            <p>{{ data?.details?.number_of_nights }}</p>
          </div>

          <div *ngIf="data?.details?.amenities">
            <h5>ESSENTIALIST AMENITIES</h5>

            <div [innerHTML]="data?.details?.amenities"></div>
          </div>
        </div>

        <div *ngIf="data?.details?.status_slug === 'proposal' || data?.details?.is_alternative_for" fxFlex="1 1 100%">
          <div *ngIf="data?.details?.price_from || data?.details?.price_to">
            <h5>PRICE</h5>

            <p *ngIf="data?.details?.price_from">${{ data?.details?.price_from }}</p>

            <p *ngIf="data?.details?.price_to">
              <span *ngIf="data?.details?.price_from">-</span>

              ${{ data?.details?.price_to }}
            </p>

            <p *ngIf="data?.details?.price_details">{{ data?.details?.price_details }}</p>
          </div>

          <div *ngIf="data?.details?.whats_included">
            <h5>INCLUSIONS</h5>

            <div [innerHTML]="data?.details?.whats_included"></div>
          </div>

          <div *ngIf="data?.details?.opening_time">
            <h5>OPENING TIMES</h5>

            <div [innerHTML]="data?.details?.opening_time"></div>
          </div>

          <div *ngIf="data?.details?.duration && getFormattedTime(data?.details?.duration, true)">
            <h5>ESTIMATED DURATION</h5>

            <p>{{ getFormattedTime(data?.details?.duration, true) }}</p>
          </div>

          <div *ngIf="data?.details?.notes">
            <h5>NOTES</h5>

            <div [innerHTML]="data?.details?.notes"></div>
          </div>
        </div>

        <div *ngIf="data?.details?.status_slug === 'confirmed'" fxFlex="1 1 100%">
          <div *ngIf="data?.details?.meeting_point">
            <h5>PICK UP DETAILS</h5>

            <p>{{ data?.details?.meeting_point }}</p>
          </div>

          <div *ngIf="data?.details?.vehicle_type">
            <h5>VEHICLE TYPE</h5>

            <p>{{ data?.details?.vehicle_type }}</p>
          </div>

          <div *ngIf="data?.details?.contact_details">
            <h5>CONTACT DETAILS</h5>

            <p>{{ data?.details?.contact_details }}</p>
          </div>

          <div *ngIf="data?.details?.whats_included">
            <h5>INCLUSIONS</h5>

            <div [innerHTML]="data?.details?.whats_included"></div>
          </div>

          <div *ngIf="data?.details?.tipping">
            <h5>TIPPING</h5>

            <p>{{ data?.details?.tipping }}</p>
          </div>

          <div *ngIf="data?.details?.opening_time">
            <h5>OPENING TIME</h5>

            <div [innerHTML]="data?.details?.opening_time"></div>
          </div>

          <div *ngIf="data?.details?.duration && getFormattedTime(data?.details?.duration, true)">
            <h5>ESTIMATED DURATION</h5>

            <p>{{ getFormattedTime(data?.details?.duration, true) }}</p>
          </div>

          <div *ngIf="data?.details?.notes">
            <h5>NOTES</h5>

            <div [innerHTML]="data?.details?.notes"></div>
          </div>

          <div *ngIf="data?.details?.cancellation_policies">
            <h5>CANCELLATION POLICIES</h5>

            <p [innerHTML]="data?.details?.cancellation_policies"></p>
          </div>
        </div>

        <div
          *ngIf="
            ((data?.details?.location && data?.details?.location.latitude && data?.details?.location.longitude) ||
              data?.details?.hotel) &&
            data?.details?.address_object
          "
          fxFlex="1 1 100%"
        >
          <h5>LOCATION</h5>

          <p>
            <!-- Info to show when this dialog is openned from a nearby section -->
            <span *ngIf="data?.details?.address && !data?.details?.address_object?.address">{{
              data?.details?.address
            }}</span>

            <span *ngIf="data?.details?.city && !data?.details?.address_object?.city">, {{ data?.details?.city }}</span>

            <span *ngIf="data?.details?.zipcode && !data?.details?.address_object?.zipcode"
              >, {{ data?.details?.zipcode }}</span
            >

            <!-- Info to show when this dialog is openned from an itinerary (place has date) -->
            <span *ngIf="data?.details?.address_object?.address">
              {{ data?.details?.address_object?.address }}
            </span>

            <span *ngIf="data?.details?.address_object?.zipcode">
              <br />
              {{ data?.details?.address_object?.zipcode }}
            </span>

            <span *ngIf="data?.details?.address_object?.city"> , {{ data?.details?.address_object?.city }} </span>

            <span *ngIf="data?.details?.address_object?.country">
              {{ data?.details?.address_object?.country }}
            </span>

            <span *ngIf="data?.details?.address_object?.phone">
              <br />
              <a [href]="'tel:' + data?.details?.address_object?.phone">{{ data?.details?.address_object?.phone }}</a>
            </span>
          </p>
        </div>
      </div>

      <div
        *ngIf="data?.details?.location && data?.details?.location.longitude && data?.details?.location.latitude"
        fxFlex="1 1 auto"
        @fadeInAnimation
      >
        <div class="template__map">
          <lib-ess-map
            gestureHandling="cooperative"
            [isUsingForm]="false"
            [entity]="data.details"
            [places]="[data.details]"
            width="100%"
          ></lib-ess-map>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
