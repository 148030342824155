<div fxLayoutAlign="space-between center" fxLayoutGap="0.625rem">
  <div fxFlex="1 1 auto">
    <p class="message" *ngIf="data.message">{{ data.message }}</p>
    <div class="html-container" *ngIf="data.html" [innerHTML]="getSafeHtml()"></div>
    <button class="action-button" *ngIf="data.action" mat-button (click)="actionBtnClicked()">
      {{ data.action }}
    </button>
  </div>

  <div fxFlex="0 0 auto">
    <button mat-icon-button (click)="closeSnackBar()" aria-label="close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
