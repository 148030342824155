import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewComponent } from './components/review/review.component';
import { ReviewListComponent } from './components/review-list/review-list.component';
import { MatIconModule } from '@angular/material/icon';
import { RatingComponent } from './components/rating/rating.component';
import { StarGroupComponent } from './components/star-group/star-group.component';

@NgModule({
  declarations: [ReviewComponent, ReviewListComponent, RatingComponent, StarGroupComponent],
  imports: [CommonModule, MatIconModule],
  exports: [ReviewListComponent, RatingComponent],
})
export class ReviewingModule {}
