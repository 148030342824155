import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageService } from './services/image.service';
import { MapService } from './services/map.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ImageService, MapService],
})
export class EssAngularComponentsModule {
  public static forRoot(environment: any): ModuleWithProviders<EssAngularComponentsModule> {
    return {
      ngModule: EssAngularComponentsModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment,
        },
      ],
    };
  }
}
