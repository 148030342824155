import { InternalRanking } from '@ess-front/angular-components';

export interface ExperienceRanking {
  icons: number[];
  isTemporary: boolean;
  title: string;
}

export const EXPERIENCE_RANKING_MAP: Record<InternalRanking, ExperienceRanking | null> = {
  [InternalRanking.CURATED]: { icons: [0, 0, 0], title: 'Curated', isTemporary: false },
  [InternalRanking.NON_CURATED]: { icons: [0, 0], title: 'Non curated', isTemporary: false },
  [InternalRanking.PERMANENTLY_CLOSED]: null,
  [InternalRanking.SIGNATURE]: { icons: [0, 0, 0, 0], title: 'Signature', isTemporary: false },
  [InternalRanking.TEMPORARY]: { icons: [0], title: 'Temporary', isTemporary: true },
};
