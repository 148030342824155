import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { environment } from '@environment';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, retryWhen, tap } from 'rxjs/operators';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class PDFService {
  domain: string;

  constructor(private readonly httpClient: HttpClient, private readonly notificationService: NotificationService) {
    this.domain = `${environment.apiEnv}api/admin/`;
  }

  downloadPDF$(endpoint: string): Observable<PDF> {
    this.notificationService.notifyWithComponent('Please, wait. PDF is downloading.', {
      panelClass: 'notification-info',
      duration: undefined,
    });
    const url = `${this.domain}${endpoint}`;
    return this.httpClient.request('GET', url, { observe: 'response' }).pipe(
      catchError(error => of(error)),
      map((response: HttpResponse<PDF>) => {
        if (response.status === HttpStatusCode.Accepted) {
          throw response.status;
        }
        if (response instanceof HttpErrorResponse) {
          return response;
        }
        this.notificationService.notifyWithComponent(
          'PDF has been successfully downloaded.',
          { duration: 10000 },
          null,
          `<div style="font-size: var(--font-sm)">If you don't see downloaded file
                    <a href="${response.body.url}" download target="_blank" style="color: inherit">click here</a>.
                </div>`,
        );
        return response.body;
      }),
      retryWhen(errors => errors.pipe(delay(2000))),
      tap(result => {
        if (!(result instanceof HttpErrorResponse)) {
          this.downloadFileByUrl(result.url);
        }
      }),
    );
  }

  private downloadFileByUrl(url: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export interface PDF {
  url: string;
}
