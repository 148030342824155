<div class="gallery" fxLayoutAlign="space-between center">
  <button
    mat-icon-button
    color="primary"
    class="scroll_button"
    [disabled]="scrollRightButtonDisabled || disableButtons"
    (click)="scrollIt('right')"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>

  <div class="gallery_container" fxLayoutAlign="start start" fxFlex="0 1 100%" #galleryContainer>
    <div fxLayoutAlign="start start" fxLayoutGap=" 1.25rem" #galleryInnerContainer>
      <ng-content></ng-content>
    </div>
  </div>

  <button
    mat-icon-button
    color="primary"
    class="scroll_button"
    [disabled]="scrollLeftButtonDisabled || disableButtons"
    (click)="scrollIt('left')"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
