import { Subject } from 'rxjs';
import DirectionsResult = google.maps.DirectionsResult;

export enum CustomTravelMode {
  FLIGHT = 'FLIGHT',
  BICYCLING = 'BICYCLING',
  DRIVING = 'DRIVING',
  TRANSIT = 'TRANSIT',
  TWO_WHEELER = 'TWO_WHEELER',
  WALKING = 'WALKING',
}

export class AutocompleteDirectionsHandler {
  destinationMarker: google.maps.Marker;
  destinationLocation: any;
  directionsRenderer: google.maps.DirectionsRenderer;
  directionsService: google.maps.DirectionsService;
  iconActive: string;
  iconPlaceA: string;
  iconPlaceB: string;
  map: google.maps.Map;
  myPolygon: google.maps.Polygon;
  originLocation: any;
  sourceMarker: google.maps.Marker;
  travelMode: google.maps.TravelMode | CustomTravelMode = google.maps.TravelMode.WALKING;

  directionsChange$ = new Subject<google.maps.LatLng[]>();

  constructor(map: google.maps.Map, private environment: any) {
    if (!map) {
      console.error('There is not any map to setup');
      return;
    }
    this.map = map;
    this.iconActive = `${this.environment.cloudinaryURL}/v1609233027/icons/mapa_active.svg`;
    this.iconPlaceA = `${this.environment.cloudinaryURL}/v1611756086/icons/mapa-transfer-A-mapa.svg`;
    this.iconPlaceB = `${this.environment.cloudinaryURL}/v1611756086/icons/mapa-transfer-B-mapa.svg`;
    this.destinationLocation = null;
    this.originLocation = null;
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      polylineOptions: { strokeColor: '#303030', strokeWeight: 2, strokeOpacity: 1 },
      suppressMarkers: true,
    });
    this.directionsRenderer.setMap(map);
  }

  async route(): Promise<void> {
    this.clearMap();

    if (!this.originLocation || !this.destinationLocation) {
      return;
    }
    if (this.travelMode === CustomTravelMode.FLIGHT) {
      await this.sleep(100);
      this.createLineRoute();
    } else {
      try {
        const response = await this.directionsService.route({
          origin: this.originLocation,
          destination: this.destinationLocation,
          travelMode: this.travelMode as google.maps.TravelMode,
        });
        this.directionsRenderer.setDirections(response);
        this.createMarkers();
      } catch (error) {
        if (error.code === google.maps.DirectionsStatus.ZERO_RESULTS || error.code === 'MAX_ROUTE_LENGTH_EXCEEDED') {
          await this.sleep(0);
          this.createLineRoute();
        } else {
          console.error('Directions request failed due to ' + error.code);
        }
      }
    }

    this.directionsChange$.next([this.originLocation, this.destinationLocation]);
  }

  private clearMap(): void {
    // Clearing last polygon drawn
    if (this.myPolygon) {
      this.myPolygon.setMap(null);
    }

    if (this.destinationMarker) {
      this.destinationMarker.setMap(null);
    }

    if (this.sourceMarker) {
      this.sourceMarker.setMap(null);
    }

    // Clearing last route drawn
    this.directionsRenderer.setDirections({ routes: [], geocoded_waypoints: [] } as DirectionsResult); // cast needed with google maps types update
  }

  private createMarkers(): void {
    // Add Marker to source and destination
    this.sourceMarker = new google.maps.Marker({
      position: this.originLocation,
      icon: this.iconPlaceA,
      map: this.map,
    } as google.maps.MapOptions);

    this.destinationMarker = new google.maps.Marker({
      position: this.destinationLocation,
      icon: this.iconPlaceB,
      map: this.map,
    } as google.maps.MapOptions);
  }

  private createLineRoute(): void {
    this.clearMap();
    const coords = [this.originLocation, this.destinationLocation];

    // Styling & Controls
    this.myPolygon = new google.maps.Polygon({
      paths: coords,
      draggable: false,
      editable: false,
      strokeWeight: 2,
      strokeOpacity: 1,
      strokeColor: '#303030',
    });

    this.createMarkers();

    this.myPolygon.setMap(this.map);

    // Center to cover all markers
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.originLocation);
    bounds.extend(this.destinationLocation);
    this.map.fitBounds(bounds);
    this.map.setCenter(bounds.getCenter());
  }

  private sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
