import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ScrollGalleryComponent } from './component/scroll-gallery.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollGalleryWrapperModule } from '../scroll-gallery-wrapper/scroll-gallery-wrapper.module';
import { ExperienceRankingComponent } from '../../internal-ranking/experience-ranking.component';

@NgModule({
  declarations: [ScrollGalleryComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDialogModule,
    ScrollGalleryWrapperModule,
    DragDropModule,
    ExperienceRankingComponent,
  ],
  exports: [ScrollGalleryComponent],
})
export class ScrollGalleryModule {}
