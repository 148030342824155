import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ImageService } from '../../core/services/image.service';
import { IMapPlaceInfo, MapComponent } from '../../map/components/map.component';

import { MapService } from '../../core/services/map.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExperienceType, ILocation, UtilsService } from '@ess-front/shared';
import { isDevice } from '../../../utils/is-device.class';
import { ListItem } from '../../core/models/http-response.model';

@Component({
  selector: 'lib-ess-map-loc-container',
  templateUrl: './map-loc-container.component.html',
  styleUrls: ['./map-loc-container.component.scss'],
})
export class MapLocContainerComponent implements OnInit, OnDestroy {
  @ViewChild('mapLoc') mapLoc: MapComponent;

  /* eslint-disable no-underscore-dangle */
  @Input() set locations(value: ILocation[]) {
    this._locations = value ? value.filter(loc => loc?.location?.latitude && loc?.location?.longitude) : null;
    if (this.isLoaded) {
      this.initLocations();
    }
  }
  get locations(): ILocation[] {
    return this._locations;
  }
  /* eslint-enable no-underscore-dangle */
  @Input() showCategories = true;
  @Input() showTabs = true;
  @Input() title = 'Along the way';
  @Output() readMore = new EventEmitter<any>();

  latLngLocations: google.maps.LatLngLiteral[];
  categories: ListItem[];
  filterCategory: string;
  filtered: ILocation[];
  filterType: string;
  formattedLoc: any = {};
  isDevice = false;
  itemIndexSelected: number;
  selectedItemId: string | null;
  thumbnails: string[];
  types = {
    aall: 'View All',
    [ExperienceType.ACCOMMODATION]: 'Stay',
    [ExperienceType.EAT_DRINK]: 'Eat & Drink',
    [ExperienceType.EXPERIENCE]: 'Do',
    [ExperienceType.TRANSPORTATION]: 'Go',
    [ExperienceType.PLACES_INTEREST]: 'See',
  };
  typesModel = ExperienceType;

  private _locations: ILocation[];
  private noneType = 'aall';
  private destroy$ = new Subject<void>();
  private isLoaded = false;

  constructor(
    private readonly imageService: ImageService,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private mapService: MapService,
    private utilsService: UtilsService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.isDevice = this.isSmallScreen();

    window.addEventListener(
      'resize',
      () => {
        this.isDevice = this.isSmallScreen();
        this.changeDetectorRef.detectChanges();
      },
      { passive: true },
    );

    this.mapService.isLoaded$.pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      this.isLoaded = isLoaded;
      if (isLoaded) {
        this.formattedLoc.aall = [];
        this.thumbnails = [];
        this.initCategories();
        this.initLocations();
      }
    });
  }

  /**
   * When a tab is selected then the items are filtered by tab's type
   * @param type
   */
  filterBy(type: string, refreshCategories = false): void {
    this.itemIndexSelected = null;
    this.selectedItemId = null;

    if (this.filterType === type) {
      this.mapLoc.centerToCoverAllMarkers();
    }

    this.filterType = type;
    this.filtered = this.formattedLoc[type];

    if (refreshCategories) {
      this.filterCategory = null;
      this.initCategories();
      this.filtered.forEach(location => this.addCategory(location));
    }

    this.updateThumbnails(this.filtered);
    this.changeDetectorRef.detectChanges();
  }

  onFilterChange(event: Event) {
    const selectedValue = (event.target as HTMLInputElement).value;
    this.filterByCategory(selectedValue);
  }

  /**
   * Each tab has another filter by category
   * @param category
   */
  filterByCategory(category: string): void {
    this.filterCategory = category;
    if (category) {
      this.filtered = this.formattedLoc[this.filterType].filter(item => item.category_slug === category);
    } else {
      this.filtered = this.formattedLoc[this.filterType];
    }
    this.updateThumbnails(this.filtered);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Scroll in the nav list and center to the item if it's needed
   * @param item
   * @param centerTo
   */
  moveToTarget(item: ILocation | IMapPlaceInfo, centerTo = false): void {
    this.selectedItemId = this.getIdItem(item);
    this.itemIndexSelected = this.locations.findIndex(itemA => this.getIdItem(itemA) === this.selectedItemId);
    // If the item is not in the filtered list, we filter again with all categories.
    if (!this.filtered.some(itemA => this.getIdItem(itemA) === this.selectedItemId)) {
      this.filterByCategory(null);
    }
    // Navigate to the item
    const $navItem = this.elementRef.nativeElement.querySelector(`.item#item_${this.selectedItemId}`);
    const $navContainer = this.isSmallScreen()
      ? this.elementRef.nativeElement.querySelector('ul.list')
      : this.elementRef.nativeElement.querySelector('.nav-container');
    const callback = ([entry], observer) => {
      const ratio = entry.intersectionRatio;
      const parentRect = $navContainer.getBoundingClientRect();
      const childRect = $navItem.getBoundingClientRect();
      if (ratio < 0.8) {
        const top = this.isSmallScreen() ? 0 : childRect.top + $navContainer.scrollTop - parentRect.top;
        const left = !this.isSmallScreen() ? 0 : childRect.left + $navContainer.scrollLeft - parentRect.left;
        $navContainer.scroll({ top, left, behavior: 'smooth' });
      }
      observer.disconnect();
    };
    this.utilsService.createIntersectionObserver(callback, $navItem, { root: $navContainer });

    if (centerTo) {
      this.mapLoc.centerTo(this.itemIndexSelected);
    }

    this.changeDetectorRef.detectChanges();
  }

  readMoreEvent(place: IMapPlaceInfo): void {
    this.readMore.emit(place);
  }

  /**
   * The map could have id or slug, depending of the entity's type
   * @param item
   */
  getIdItem(item: IMapPlaceInfo | ILocation): string {
    return Object.prototype.hasOwnProperty.call(item, 'id') ? item.id.toString() : item.slug;
  }

  private isSmallScreen(): boolean {
    return document.body.clientWidth < 961 || isDevice();
  }

  /**
   * The locations are grouped by its type + 'view all'
   * @param ac
   * @param current
   * @private
   */
  private groupByType(ac: any, current: ILocation): { [key: string]: ILocation[] } {
    // Getting the thumbnails
    this.thumbnails.push(this.getLocationImage(current));

    // Collecting categories for categories' filter
    this.addCategory(current);
    if (!Object.prototype.hasOwnProperty.call(ac, current.type_slug)) {
      ac[current.type_slug] = [];
    }
    ac[current.type_slug].push(current);
    ac.aall.push(current);
    return ac;
  }

  private addCategory(location: ILocation): void {
    if (!this.categories.find(item => item.value === location.category_slug)) {
      this.categories.push(new ListItem(location.category, location.category_slug));
    }
  }

  private initCategories(): void {
    this.categories = [new ListItem('All Categories', null)];
  }

  private initLocations(): void {
    if (this.locations) {
      // Because the map needs lat,lng coords and the api returns "latitude, longitude"
      this.latLngLocations = this.locations.map(loc => this.mapService.fromCustomLocation(loc.location).toJSON());
      this.formattedLoc = this.locations.reduce((ac: any, current: ILocation) => this.groupByType(ac, current), {
        aall: [],
      });
      this.filterBy(Object.keys(this.formattedLoc)[0], false);
    }
  }

  private updateThumbnails(locations: ILocation[]): void {
    this.thumbnails = locations.map(location => this.getLocationImage(location));
  }

  private getLocationImage(location: ILocation): string {
    return this.imageService.getImageUrl(location.image || location.featured_image, 65, 86);
  }
}
