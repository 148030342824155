import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'ess-two-columns',
  templateUrl: './two-columns.component.html',
  styleUrls: ['./two-columns.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition('* => *', [
        style({
          opacity: 0,
        }),
        animate(
          '400ms 150ms ease-in-out',
          style({
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ],
})
export class TwoColumnsComponent {}
