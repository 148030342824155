import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateTimeFormatService {
  getFormattedTime(time: string, durationFormat: boolean): string | void {
    if (!time) {
      return;
    }

    const hours = Number(time.split(':')[0]);
    const minutes = Number(time.split(':')[1]);
    let formattedTime = '';
    const hoursMap = {
      12: 12,
      13: 1,
      14: 2,
      15: 3,
      16: 4,
      17: 5,
      18: 6,
      19: 7,
      20: 8,
      21: 9,
      22: 10,
      23: 11,
      24: 0,
    };

    if (durationFormat) {
      if (!hours && !minutes) {
        return null;
      }

      if (hours) {
        formattedTime += `${hours}h`;
      }

      formattedTime += hours ? `, ${minutes}min` : `${minutes}min`;
    } else if (!hours && !minutes) {
      formattedTime = `12:00 a.m.`;
    } else if (hours < 12) {
      formattedTime += `${time.split(':')[0]}:${time.split(':')[1]} a.m.`;
    } else {
      formattedTime += `${hoursMap[hours]}:${time.split(':')[1]} p.m.`;
    }

    return formattedTime;
  }

  dateToPlainString(date: Date | null): string {
    if (!date || !(date instanceof Date)) {
      return '';
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day}`;
  }
}
