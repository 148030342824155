<button
  *ngIf="!icon"
  pButton
  type="button"
  [label]="label"
  [disabled]="isDisabled"
  [class]="'ess-button ' + styleClass"
  (click)="onClickEvent()"
></button>
<button
  *ngIf="icon"
  pButton
  type="button"
  [label]="label"
  [icon]="'pi ' + icon"
  [disabled]="isDisabled"
  [class]="'ess-button ' + styleClass"
  (click)="onClickEvent()"
></button>
