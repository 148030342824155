<ng-container *ngIf="review">
  <div class="header">
    <p class="font-semibold">{{ review.user }}</p>
    <p class="font-semibold">-</p>
    <p class="font-semibold">{{ review.booking }},</p>
    <p class="font-semibold">{{ review.date | date }}:</p>
  </div>
  <p class="font-medium">"{{ review.comment }}"</p>
  <span class="rating">
    <ess-star-group [rate]="1"></ess-star-group>
    <p class="font-semibold">{{ review.score }}</p>
  </span>
</ng-container>
