import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapModule } from '../map/map.module';
import { MapLocContainerComponent } from './components/map-loc-container.component';
import { ButtonModule } from '../button/button.module';
import { TabViewModule as PrimeNGTabViewModule } from 'primeng/tabview';
import { DropdownModule } from '../dropdown/dropdown.module';
import { IconStatusModule } from '@ess-front/shared';

@NgModule({
  declarations: [MapLocContainerComponent],
  imports: [CommonModule, MapModule, ButtonModule, PrimeNGTabViewModule, DropdownModule, IconStatusModule],
  exports: [MapLocContainerComponent],
})
export class MapLocContainerModule {}
