import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-ess-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() icon: string;
  @Input() isDisabled = false;
  @Input() label: string;
  @Input() styleClass: string;

  @Output() clicked = new EventEmitter();

  onClickEvent(): void {
    this.clicked.emit();
  }
}
