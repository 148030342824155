import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ISnackBarData } from './notification.service';

@Component({
  selector: 'ess-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData,
    private readonly snackBar: MatSnackBar,
    private snackBarRef: MatSnackBarRef<NotificationComponent>,
    private domSanitizer: DomSanitizer,
  ) {}

  closeSnackBar(): void {
    this.snackBar.dismiss();
  }

  actionBtnClicked(): void {
    this.snackBarRef.dismissWithAction();
  }

  getSafeHtml(): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(this.data.html);
  }
}
