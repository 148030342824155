import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { InternalRanking } from '@ess-front/angular-components';
import { EXPERIENCE_RANKING_MAP, ExperienceRanking } from './experience-ranking.model';

@Component({
  selector: 'ess-experience-ranking',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './experience-ranking.component.html',
  styleUrls: ['./experience-ranking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceRankingComponent implements OnInit {
  @Input() internalRanking: InternalRanking | null = null;
  ranking: ExperienceRanking | null;

  ngOnInit(): void {
    this.ranking = this.internalRanking ? EXPERIENCE_RANKING_MAP[this.internalRanking] : null;
  }
}
