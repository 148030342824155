import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ReviewBE } from '../models/review-be.model';
import { environment } from '@environment';
import { Review } from '../models/review.model';
import { HttpListResponse } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private readonly httpClient: HttpClient) {}

  getFeedback$(itemId: string, experienceObjectType: string): Observable<Review[]> {
    const URL = `${environment.apiEnv}api/private/feedback/${experienceObjectType}/${itemId}`;
    return this.httpClient
      .get<HttpListResponse<ReviewBE>>(URL)
      .pipe(map(response => this.transformIntoReviewList(response)));
  }

  private transformIntoReviewList(feedback: HttpListResponse<ReviewBE>): Review[] {
    const { results } = feedback;
    return results.map(review => {
      const { experience_date, score, ...rest } = review;
      return {
        date: experience_date,
        score,
        ...rest,
      };
    });
  }
}
