<section class="flex flex-col" [ngClass]="{'is-device': isDevice}">
  <!-- Tabs -->
  <div *ngIf="showTabs" class="wrapper">
    <ul class="list list-tabs-container">
      <li *ngIf="!isDevice" class="item">
        <lib-ess-button
          [label]="title + ':'"
          isDisabled="true"
          class="text-l"
          styleClass="pretty font-primary italic"
        ></lib-ess-button>
      </li>
      <ng-container *ngFor="let loc of formattedLoc | keyvalue">
        <li
          *ngIf="(loc.key !== 'aall' && isDevice) || !isDevice"
          class="item"
          [ngClass]="{'is-active': filterType === loc.key}"
        >
          <lib-ess-button
            [label]="types[loc.key]"
            (click)="filterBy(loc.key, true)"
            class="mx-3"
            [styleClass]="filterType === loc.key ? 'pretty font-primary is-active' : 'pretty font-primary'"
          ></lib-ess-button>
        </li>
      </ng-container>
      <li class="item flex-grow-1">&nbsp;</li>
    </ul>
  </div>
  <!-- Content -->
  <section class="list-items-container flex justify-between">
    <div class="nav-dropdown-container">
      @if (categories && showCategories) {
        <select name="categories-dropdown" aria-labelledby="categories" (change)="onFilterChange($event)">
          @for (category of categories; track category) {
            <option value="{{ category.value }}">
              {{ category.label }}
            </option>
          }
        </select>
      }
      <div class="nav-container flex flex-col" tabindex="0">
        <nav>
          <ul class="list flex-col">
            <li
              #listItem
              *ngFor="let item of filtered; let i = index"
              [id]="'item_' + getIdItem(item)"
              class="item"
              [ngClass]="{'is-active': listItem.id === 'item_' + selectedItemId, 'w-full': isDevice}"
              (click)="moveToTarget(item, true)"
            >
              <div class="text-container w-full">
                <figure>
                  <img [src]="thumbnails[i]" [attr.aria-label]="item.title" />
                </figure>
                <div class="info">
                  <div>
                    <p class="pre-title">{{ item.type }}</p>
                    <h3 class="m-0 text-normal font-light">{{ item.title }}</h3>
                  </div>
                  <ess-icon-status
                    *ngIf="item.status_slug && !item.is_alternative_for"
                    [status]="item.status_slug"
                  ></ess-icon-status>
                  <ess-icon-status *ngIf="item.is_alternative_for" status="alternative"></ess-icon-status>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div *ngIf="!isDevice" class="separator vertical"></div>
    <lib-ess-map
      #mapLoc
      width="100%"
      (clickMarker)="moveToTarget($event)"
      (clickReadMore)="readMoreEvent($event)"
      [height]="isDevice ? '50vh' : '500px'"
      [isUsingForm]="false"
      [selectedType]="filterType"
      [locations]="latLngLocations"
      [places]="locations"
    ></lib-ess-map>
  </section>
</section>
